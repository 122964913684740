<template>
  <div class="switch-locale">
    <button class="locale-btn active">
      Ру
    </button>
    <button class="locale-btn">
      En
    </button>
  </div>
</template>

<script>
export default {
  name: 'AppSwitchLocale'
}
</script>

<style lang="scss" scoped>
  .switch-locale {
    display: flex;
    align-items: center;
    .locale-btn {
      color: $gray;
      padding: 10px;
      margin-right: 6px;
      @include max-w-xs {
        padding: 8px;
      }
      &:last-child {
        margin-right: 0;
      }
      &.active {
        border-radius: $border-radius-base;
        background: $white;
        color: $dark;
        box-shadow: $box-shadow-base;
      }
    }
  }
</style>
