<template>
  <component
    :is="to ? 'router-link' : href ? 'a' : 'button'"
    class="wrapper-btn"
    :class="{ 'no-description' : !description }"
    :to="to"
    :href="href"
  >
    <div class="btn btn-main" :class="{ primary : primary }">
      <slot />
    </div>
    <div class="btn-info">
      <p class="btn-title fs--base">
        {{ title }}
      </p>
      <div class="wrapper-divider">
        <div class="divider" />
        <icon-half-arrow-right class="icon" />
      </div>
      <p class="fs--small description fw--light">
        {{ description }}
      </p>
    </div>
  </component>
</template>

<script>
import IconHalfArrowRight from '@/assets/img/icons/half-arr-right.svg'

export default {
  name: 'AppButton',
  components: {
    IconHalfArrowRight
  },
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: ''
    },
    primary: {
      type: Boolean,
      default: false
    },
    to: {
      type: String,
      default: null
    },
    href: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
  .wrapper-btn {
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    &.no-description {
      align-items: flex-start;
    }
    .btn {
      margin-right: 16px;
      @include max-w-lg {
        margin-right: 12px;
      }
      @include max-w-xs {
        margin-right: 8px;
      }
      svg {
        width: 24px;
        height: 24px;
        @include max-w-lg {
          width: 20px;
          height: 20px;
        }
        @include max-w-xs {
          width: 16px;
          height: 16px;
        }
      }
    }
    .btn-info {
      width: 100%;
      text-align: left;
      .btn-title {
        font-weight: 500;
        color: $dark;
      }
      .wrapper-divider {
        position: relative;
        .divider {
          width: calc(100% - 30px);
          margin: 8px 0;
          @include max-w-lg {
            margin: 6px 0;
          }
          @include max-w-xs {
            margin: 4px 0;
          }
        }
      }
      .icon {
        position: absolute;
        right: 0;
        top: calc(50% - 13px);
        @include max-w-xs {
          right: 0px;
        }
      }
      .description {
        color: $gray;
        @include max-w-xs {
          font-size: calc(#{$font-size-small} - 2px);
        }
      }
    }
  }
</style>
